<template>
	<div>
		<div class="container">
			<div class="handle-box">
				<el-select size="small" v-if="isAdmin" @change="agentChange" v-model="query.agentId" filterable
					clearable placeholder="代理商" class="handle-select mr10" style="width: 260px;">
					<el-option v-for="item in agents" :key="item.value" :label="item.label"
						:value="item.value"></el-option>
				</el-select>
				<el-select size="small" v-if="isSubAgent" v-model="query.subAgentId" @change="getfetchDefaultCompanies"
					filterable clearable placeholder="子代理" class="handle-select mr10" style="width: 260px;">
					<el-option v-for="item in subAgentList" :key="item.id" :label="item.name"
						:value="item.id"></el-option>
				</el-select>
				<el-select size="small" style="width: 260px;" v-if="isAgent" v-model="query.companyId"
					@change="companyChange" filterable clearable placeholder="企业" class="handle-select mr10">
					<el-option v-for="item in companys" :key="item.value" :label="item.label"
						:value="item.value"></el-option>
				</el-select>
				<el-select size="small" v-model="query.departmentId" @change="getdepartment" filterable
					v-show="isNormal" clearable placeholder="部门" class="handle-select mr10" style="width: 180px;">
					<el-option v-for="item in departments" :key="item.value" :label="item.label"
						:value="item.value"></el-option>
				</el-select>
				<el-select size="small" v-model="query.account" v-show="isNormal" filterable clearable placeholder="员工"
					class="handle-select mr10">
					<el-option v-for="item in accountList" :key="item.account" :label="item.userPhone"
						:value="item.account"></el-option>
				</el-select>
				<el-button size="small" type="primary" :class="[theme]" icon="el-icon-search" @click="handleSearch"
					v-if="isNormal">搜索</el-button>

				<el-button size="small" type="primary" :class="[theme]" class="handle-add mr100" v-if="isNormal"
					@click="showForm">开通账号</el-button>
				<i class="el-icon-warning-outline tip-box" v-if="isNormal">
					<div>
						<p>1.使用寻迹通寻找数据前，请先开通账号。</p>
						<p>2.开通后可预览全网公开数据，账号开通后可领取50条试用或直接购买套餐。</p>
						<p>3.领取数据前请在公海数据建立“数据池”。数据池类型：“店铺”“线索”。</p>
						<p>对应类型的线索池只能导入对应类型的数据。(例如:店铺线索池只能领取店铺类型数据)</p>
					</div>
				</i>
			</div>

			<el-table :data="tableData" border class="table" ref="multipleTable" header-cell-class-name="table-header"
				:row-style="{ height: '20px' }" :cell-style="{ padding: '6px' }"
				:header-cell-style="{ 'text-align': 'center' }" :row-class-name="tableRowClassName"
				style="width: 100%;">
				<el-table-column prop="userPhone" label="用户名称" align="center" min-width="16%"></el-table-column>
				<el-table-column prop="cycle" label="当前计费周期" align="center" min-width="16%"></el-table-column>
				<el-table-column prop="month" label="剩余月数" align="center" min-width="16%"></el-table-column>
				<el-table-column prop="userCount" label="周期内可用" align="center" min-width="16%"></el-table-column>
				<el-table-column prop="userMonthUsed" label="周期内已用" align="center" min-width="16%"></el-table-column>
				<el-table-column label="操作" align="center" min-width="16%">
					<template #default="scope">
						<el-button size="small" type="text" @click="purchaseData(scope.row.userId)">购买数据</el-button>
						<el-button v-if="scope.row.trial == true" size="small" type="text" disabled>领取试用</el-button>
						<el-button v-if="scope.row.trial == false" size="small" type="text" icon="el-icon-edit"
							@click="getTrials(scope.row.account)">领取试用</el-button>
					</template>
				</el-table-column>
			</el-table>
		</div>

		<el-dialog title="开通账号" v-model="addVisible" width="450px" @close="closeDialog('form')"
			:close-on-click-modal="false">
			<el-form :model="form" :rules="rules" ref="form" label-width="100px">
				<el-form-item label="代理" prop="agentId" v-if="isAdmin">
					<el-select size="small" @change="agentChange" v-model="form.agentId" filterable clearable
						placeholder="代理商" class="handle-select mr10" style="width: 100%;" :disabled="companyDis">
						<el-option v-for="item in agents" :key="item.value" :label="item.label"
							:value="item.value"></el-option>
					</el-select>
				</el-form-item>
				<el-form-item label="企业" prop="companyId" v-if="isAgent">
					<el-select size="small" v-model="form.companyId" @change="companyChange" :disabled="companyDis"
						filterable clearable placeholder="企业" class="handle-select mr10" style="width: 100%;">
						<el-option v-for="item in companys" :key="item.value" :label="item.label"
							:value="item.value"></el-option>
					</el-select>
				</el-form-item>
				<el-form-item label="部门" prop="departmentId" v-if="isNormal">
					<el-select size="small" v-model="form.departmentId" @change="queryUser" filterable clearable
						placeholder="部门" class="handle-select mr10" style="width: 100%;">
						<el-option v-for="item in departments" :key="item.value" :label="item.label"
							:value="item.value"></el-option>
					</el-select>
				</el-form-item>
				<el-form-item v-if="nameList.length > 0" label="可分配员工" prop="departmentId">
					<el-checkbox-group v-model="form.nameList" @change="handleCheckedCitiesChange">
						<el-checkbox v-for="item in nameList" :label="item.label" :key="item.value" border
							style="min-width: 30%;margin:0;margin-bottom:10px;margin-right: 1.5%;">
							{{ item.value }}
						</el-checkbox>
					</el-checkbox-group>
				</el-form-item>
			</el-form>
			<div class="dialog-footer myRight">
				<el-button size="small" @click="closeDialog('form')">取消</el-button>
				<el-button size="small" type="primary" :class="[theme]" @click="getopenAccount('form')">确定</el-button>
			</div>
		</el-dialog>

		<div class="edit-box">
			<el-dialog v-model="editVisible" width="1600" :show-close="true">
				<div><img style="width: 100.1%;position: absolute;left: 0;top: 0;" src="../assets/img/pay3.png" alt=""
						fit="cover" /></div>
				<div class="pay-box" style="margin-top: 80px;text-align: center">
					<el-form :model="form2" ref="form2" label-width="20px">
						<el-form-item label="" style="display: inline-block;">
							<div style="text-align: center;font-weight: 600;margin-bottom: 40px;font-size: 18px;">版本选择
							</div>
							<el-radio-group v-model="form2.type" @change="getType">
								<el-radio label="XJT_30">
									<div class="tip">灵活快捷</div>
									<div class="radio-box">
										<div class="radio-tit">包月版</div>
										<div class="radio-price">
											<span>150</span>
											/月
										</div>
										<div class="radio-tip">
											<span>15000条</span>
											<span>平均每天可领取500条</span>
										</div>
									</div>
								</el-radio>
								<el-radio label="XJT_365">
									<div class="tip">实惠划算</div>
									<div class="radio-box">
										<div class="radio-tit">包年版</div>
										<div class="radio-price">
											<span>1180</span>
											/年
										</div>
										<div class="radio-tip">
											<span>180000条</span>
											<span>每月最高解锁15000条</span>
										</div>
									</div>
								</el-radio>
							</el-radio-group>
							<div class="price-info" v-if="form2.type == 'XJT_30'">
								<div class="price-number">
									应付金额：
									<span style="font-size: 42px;font-weight: 600;">150</span>
									<span>元</span>
								</div>
								<div class="price-time">
									<div>
										会员期限：
										<span>1</span>
										月
									</div>
									<div>
										账号数：
										<span>1</span>
										个
									</div>
								</div>
							</div>
							<div class="price-info" v-if="form2.type == 'XJT_365'">
								<div class="price-number">
									应付金额：
									<span style="font-size: 42px;font-weight: 600;">1180</span>
									<span>元</span>
								</div>
								<div class="price-time">
									<div>
										会员期限：
										<span>1</span>
										年
									</div>
									<div>
										账号数：
										<span>1</span>
										个
									</div>
								</div>
							</div>
						</el-form-item>
						<el-form-item label="" style="display: inline-block;">
							<div class="qrcode-box">
								<div class="weixin" style="position: relative;">
									<div id="qrcode" ref="qrcode"
										style="border: 1px solid #60bd38;padding: 16px;border-radius: 6px;"></div>
									<img class="log" src="../assets/img/wxpay.png" alt="" />
									<div class="foot">
										<img class="logname" src="../assets/img/wxpay.png" alt="" />
										<span>微信支付</span>
									</div>
								</div>
								<div class="ali" style="position: relative;">
									<div id="qrcode2" ref="qrcode"
										style="border: 1px solid #4f7afd;padding: 16px;border-radius: 6px;"></div>
									<img class="log" src="../assets/img/alipay.png" alt="" />
									<div class="foot">
										<img class="logname" src="../assets/img/alipay.png" alt="" />
										<span>支付宝支付</span>
									</div>
								</div>
							</div>
						</el-form-item>
					</el-form>
				</div>
			</el-dialog>
		</div>
	</div>
</template>

<script>
	import { delDepartment, fetchDepartmentById, updateDepartment } from '../api/departmentsIndex.js';
	import { fetchDepartment } from '../api/index.js';
	import { fetchCompanyByAgentId, fetchDefaultCompanies } from '../api/companyIndex';
	import { fetchAgent } from '../api/agentIndex';
	import {
		queryAccount,
		queryEnterpriseDosage,
		getTrial,
		queryUser,
		openAccount,
		getBatch,
		phoneNumberPoolPay
	} from '../api/powerandhealth.js';
	import qs from 'qs';
	import QRCode from 'qrcodejs2';
	import { querySubAgent } from '../api/serialIndex.js';
	export default {
		name: 'poolAccount',
		data() {
			return {
				theme: localStorage.getItem('theme'),
				query: {
					departmentId: '',
					account: '',
					subAgentId: ''
				},
				tableData: [],
				agents: [],
				companys: [],
				departments: [],
				accountList: [],
				multipleSelection: [],
				delList: [],
				nameList: [],
				editVisible: false,
				addVisible: false,
				isAdmin: false,
				isSubAgent: false,
				isAgent: false,
				tipContent: '<div>1.使用寻迹通寻找数据前，请先开通账号。</br>2.开通后可预览全网公开数据，账号开通后可领取50条试用或直接购买套餐。</br>3.领取数据前请在公海数据建立“数据池”。数据池类型：“店铺”“线索”。</br>对应类型的线索池只能导入对应类型的数据。(例如:店铺线索池只能领取店铺类型数据)</div>',
				companyDis: false,
				canAddDepartment: false,
				pageTotal: 0,
				isNormal: true,
				form: {
					agentId: '',
					account: '',
					name: '',
					ownerName: '',
					companyId: '',
					ownerPhone: '',
					nameList: []
				},
				form2: {
					type: 'XJT_30',
					pattern: 'wxpay',
					userId: ''
				},
				idx: -1,
				id: -1,
				loading: false,
				token: { Authorization: localStorage.getItem('token'), UserRole: localStorage.getItem('ms_role') },
				rules: {
					agentId: [{ required: true, message: '请选择代理', trigger: 'change' }],
					companyId: [{ required: true, message: '请选择企业', trigger: 'change' }],
					name: [{ required: true, message: '请输入部门名称', trigger: 'blur' }]
				},
				iscompany: false,
				weixinUrl: false,
				alipayUrl: false,
				total_fee: '',
				qr: '',
				qr2: ''
			};
		},
		created() {
			let role = localStorage.getItem('ms_role');
			this.subAgent = localStorage.getItem('subAgent');
			if (role === 'normal') {
				this.canAddDepartment = true;
				this.isNormal = false;
				this.getData();
			}
			if (role === 'department') {
				this.canAddDepartment = true;
			}

			if (role === 'agent') {
				this.isAgent = true;
				if (this.subAgent == 'true') {
					this.isSubAgent = true;
					this.getquerySubAgent();
				} else {
					this.isSubAgent = false;
				}
			}
			if (role === 'admin') {
				this.isAdmin = true;
				this.isAdmin2 = false;
				this.isAgent = true;
			}
			if (role === 'subAgent') {
				this.isAgent = true;
			}
			if (role === 'company') {
				this.iscompany = true;
				this.getData();
			}

			this.init();
		},

		methods: {
			getfetchDefaultCompanies() {
				this.query.companyId = '';
				let data = {
					currentUserId: localStorage.getItem('user'),
					subAgentId: this.query.subAgentId
				};
				fetchDefaultCompanies(data).then(res => {
					this.companys = res.data;
				});
			},
			getquerySubAgent() {
				let data = {
					agentId: this.query.agentId,
					userId: localStorage.getItem('user')
				};
				querySubAgent(data).then(res => {
					if (res.code == 200) {
						this.subAgentList = res.data;
					} else {
						this.$message.error(res.message);
					}
				});
			},
			tableRowClassName({ rowIndex }) {
				if ((rowIndex + 1) % 2 === 0) {
					return 'oddRow';
				}
				return 'evenRow';
			},
			init() {
				fetchAgent(localStorage.getItem('user')).then(res => {
					this.agents = res.data;
				});
				if (this.subAgent == true) {
					this.isSubAgent = true;
					this.getquerySubAgent();
				}
				this.getfetchDefaultCompanies();
				let data = {
					companyId: '',
					currentUserId: localStorage.getItem('user')
				};
				fetchDepartment(data).then(res => {
					this.departments = res.data;
					if (localStorage.getItem('ms_role') == 'company') {
						this.getData();
					}
					if (localStorage.getItem('ms_role') == 'department') {
						this.query.departmentId = this.departments[0].value;
						this.getData();
					}
				});
			},
			getData() {
				if (this.iscompany) {
					this.query.companyId = localStorage.getItem('company');
				}
				let data = {
					companyId: this.query.companyId,
					userId: localStorage.getItem('user'),
					userPhone: this.query.userPhone,
					account: this.query.account
				};
				queryEnterpriseDosage(data).then(res => {
					if (res.code == 200) {
						this.tableData = res.data;
					} else {
						this.$message.error(res.message);
					}
				});
			},
			agentChange(agentId) {
				this.query.agentId = agentId;
				this.query.companyId = '';
				this.query.departmentId = '';
				this.query.userId = '';
				this.form.agentId = agentId;
				this.form.companyId = '';
				this.form.departmentId = '';
				this.form.userId = '';
				if (agentId) {
					let data = {
						subAgent: JSON.parse(localStorage.getItem('subAgent').toLowerCase()),
						agentId: agentId
					};
					fetchCompanyByAgentId(data).then(res => {
						this.companys = res.data;
					});
				}
			},
			companyChange(companyId) {
				this.query.companyId = companyId;
				this.query.departmentId = '';
				this.query.userId = '';
				this.form.companyId = companyId;
				this.form.departmentId = '';
				this.form.userId = '';
				let data = {
					companyId: companyId,
					departmentId: this.query.departmentId,
					currentUserId: localStorage.getItem('user')
				};
				fetchDepartment(data).then(res => {
					this.departments = res.data;
					let data = {
						companyId: this.query.companyId,
						departmentId: this.query.departmentId,
						userId: localStorage.getItem('user')
					};
					queryAccount(data).then(res => {
						this.accountList = res.data;
						this.query.pageIndex = 1;
						this.getData();
					});
				});
			},
			getdepartment() {
				let data = {
					companyId: this.query.companyId,
					departmentId: this.query.departmentId,
					currentUserId: localStorage.getItem('user')
				};
				fetchDepartment(data).then(res => {
					this.departments = res.data;
					let data = {
						companyId: this.query.companyId,
						departmentId: this.query.departmentId,
						userId: localStorage.getItem('user')
					};

					queryAccount(data).then(res => {
						this.accountList = res.data;
						this.query.pageIndex = 1;
						this.getData();
					});
				});
			},
			queryUser() {
				// 获取可分配数据
				let data = {
					companyId: this.query.companyId,
					departmentId: this.form.departmentId
				};
				queryUser(data).then(res => {
					if (res.code == 200) {
						this.nameList = res.data;
					} else {
						this.$message.error(res.message);
					}
				});
			},
			handleCheckedCitiesChange(value) {
				this.openAccountList = value.toString();
			},
			getopenAccount() {
				if (this.openAccountList) {
					let data = {
						userIds: this.openAccountList,
						currentUserId: localStorage.getItem('user')
					};
					openAccount(data).then(res => {
						if (res.code == 200) {
							this.$message.success('添加成功');
							this.addVisible = false;
							this.getData();
						} else {
							this.$message.error(res.message);
						}
					});
				} else {
					this.$message.error('请选择开通的用户');
				}
			},
			getBatchs() {
				getBatch(JSON.stringify(this.form2)).then(res => {
					if (res.code == 200) {
						this.$message.success('赠送成功');
						this.editVisible = false;
						this.getData();
					} else {
						this.$message.error(res.message);
					}
				});
			},
			showForm() {
				this.addVisible = true;
				this.nameList = [];
				this.form.nameList = [];
			},

			getTrials(accounts) {
				let data = {
					accounts: accounts
				};
				getTrial(data).then(res => {
					if (res.code == 200) {
						this.$message.success('领取成功');
						this.query.agentId = this.form.agentId;
						this.query.companyId = this.form.companyId;
						this.getData();
					} else {
						this.$message.error(res.message);
					}
				});
			},
			// 编辑操作
			handleEdit(account) {
				this.form2.userList = [];
				this.form2.limitNum = '';
				this.editVisible = true;
				this.form2.userList.push(account);
			},
			updateDepartment(formName) {
				this.$refs[formName].validate(valid => {
					if (valid) {
						this.loading = true;
						updateDepartment(this.form).then(res => {
							if (res.code == 200) {
								this.$message.success('修改成功');
								this.getData();
								this.init();
							} else {
								this.$message.error(res.message);
							}
							this.addVisible = false;
						});
					}
				});
			},
			// 触发搜索按钮
			handleSearch() {
				this.query.pageIndex = 1;
				this.getData();
			},
			// 删除操作
			handleDelete(index, id) {
				// 二次确认删除
				this.$confirm('确定要删除吗？', '提示', {
						type: 'warning'
					})
					.then(() => {
						delDepartment(id).then(res => {
							if (res.code == 200) {
								this.$message.success('删除成功');
								this.pageTotal = this.pageTotal - 1;
								this.tableData.splice(index, 1);
							} else {
								this.$message.error(res.message);
							}
						});
					})
					.catch(() => {});
			},
			// 分页导航
			handlePageChange(val) {
				this.query.pageIndex = val;
				this.getData();
			},
			resetForm(formName) {
				this.$refs[formName].resetFields();
			},
			closeDialogedit(formName) {
				this.editVisible = false;
				this.$refs.qrcode.innerHTML = '';
				this.weixinUrl = '';
				this.alipayUrl = '';
				this.total_fee = '';
				this.$refs['form2'].resetFields();
			},
			closeDialog(formName) {
				this.addVisible = false;
				this.companyDis = false;

				this.$refs[formName].resetFields();
			},

			purchaseData(id) {
				this.editVisible = true;
				this.form2.userId = id;
				this.pattern1 = 'wxpay';
				this.pattern2 = 'alipay';
				if (this.tr) {
					this.tr._el.innerHTML = '';
				}
				if (this.tr2) {
					this.tr2._el.innerHTML = '';
				}
				this.getpay();
				this.getpay2();
			},
			getpay() {
				let data = {
					userId: this.form2.userId,
					type: this.form2.type,
					pattern: this.pattern1
				};
				phoneNumberPoolPay(data).then(res => {
					if (res.code == 200) {
						this.url = res.data.url;
						this.total_fee = res.data.total_fee;
						this.tr = new QRCode('qrcode', {
							width: 160,
							height: 160, // 高度
							text: this.url, // 二维码内容
							colorDark: '#000000',
							colorLight: '#ffffff'
						});
					} else {
						this.$message.error(res.message);
					}
				});
			},
			getpay2() {
				let data = {
					userId: this.form2.userId,
					type: this.form2.type,
					pattern: this.pattern2
				};
				phoneNumberPoolPay(data).then(res => {
					if (res.code == 200) {
						this.url = res.data.qrCode;
						this.tr2 = new QRCode('qrcode2', {
							width: 160,
							height: 160, // 高度
							text: this.url, // 二维码内容
							colorDark: '#000000',
							colorLight: '#ffffff'
						});
					} else {
						this.$message.error(res.message);
					}
				});
			},
			getType(e) {
				console.log(e, '所得到的');
				this.tr._el.innerHTML = '';
				this.tr2._el.innerHTML = '';
				// this.weixinUrl = false;
				// this.alipayUrl = false;
				this.getpay();
				this.getpay2();
			}
		}
	};
</script>

<style scoped>
	.handle-box {
		margin-bottom: 20px;
	}

	/* a:hover{background: #66b1ff} */
	.handle-select {
		width: 240px;
	}

	.table {
		width: 100%;
		font-size: 12px;
	}

	.red {
		color: #f56c6c;
	}

	.mr10 {
		margin-right: 10px;
	}

	.mr100 {
		position: absolute;
		right: 100px;
	}

	.myRight {
		display: flex;
		flex-direction: row;
		justify-content: flex-end;
	}

	.handle-input {
		width: 200px;
		display: inline-block;
	}

	/deep/.pay-box .el-dialog {
		border-radius: 8px !important;
	}

	/deep/.pay-box .el-radio__input.is-checked .el-radio__inner {
		display: none;
	}

	/deep/.pay-box .el-radio__input .el-radio__inner {
		display: none;
	}

	/deep/.pay-box .el-radio__input.is-checked+.el-radio__label {
		color: #000;
	}

	/deep/.pay-box .el-radio.is-checked {
		border: 3px solid #f93e13;
	}

	/deep/.pay-box .el-radio {
		width: 200px;
		height: 200px;
		border-radius: 8px;
		color: #000;
		border: 3px solid #eee;
	}

	/deep/.pay-box .el-form-item__label {
		line-height: 200px;
		font-size: 18px;
	}

	.radio-box {
		display: flex;
		flex-direction: column;
		text-align: center;
		width: 200px;
		height: 200px;
		justify-content: space-evenly;
		position: relative;
		top: -13px;
	}

	.radio-box .radio-tit {
		font-size: 26px;
	}

	.radio-box .radio-price {
		font-size: 20px;
	}

	.radio-box .radio-price span {
		color: #f93e13;
		font-size: 38px;
		font-weight: 600;
	}

	.radio-box .radio-tip {
		margin: 0 20px;
		border-top: 1px dashed #ccc;
		display: flex;
		flex-direction: column;
	}

	.radio-box .radio-tip span {
		font-size: 12px;
		margin-top: 10px;
	}

	.price-info {
		width: 440px;
		text-align: center;
		margin-top: 20px;
		font-size: 26px;
	}

	.price-info .price-number {
		font-size: 22px;
	}

	.price-info .price-number span {
		color: #f93e13;
	}

	.price-info .price-time {
		display: flex;
		flex-direction: row;
		background: #fffaf6;
		border: 1px solid #fcf3e8;
		color: #8b7380;
		justify-content: space-around;
		width: 320px;
		border-radius: 6px;
		margin: 10px auto;
	}

	.price-info .price-time span {
		color: #f93e13;
	}

	.qrcode-box {
		display: flex;
		flex-direction: row;
		width: 440px;
		justify-content: center;
	}

	.qrcode-box .weixin {
		margin-right: 40px;
		position: relative;
	}

	.qrcode-box div .log {
		position: absolute;
		top: 75px;
		left: 75px;
		width: 42px;
		height: 42px;
	}

	.qrcode-box .foot {
		display: flex;
		flex-direction: row;
		justify-content: center;
		margin-top: 20px;
	}

	.qrcode-box .foot .logname {
		width: 30px;
		height: 30px;
		margin-top: 6px;
		margin-right: 10px;
	}

	.qrcode-box .foot span {
		font-size: 18px;
	}

	/deep/.el-dialog__headerbtn {
		z-index: 9999;
	}

	.edit-box /deep/.el-dialog__headerbtn .el-dialog__close {
		font-size: 26px;
		color: #fdf74e !important;
		font-weight: 600;
	}

	.tip {
		position: absolute;
		top: -30px;
		background: linear-gradient(to bottom right, #fb815d, #f93e13);
		width: 90px;
		height: 30px;
		border-radius: 25px 0px 25px 0px;
		text-align: center;
		line-height: 30px;
		color: #fff;
		left: -1px;
	}

	.tip-box {
		float: right;
		cursor: pointer;
		position: relative;
		left: -20px;
	}

	.tip-box div {
		display: none;
		width: 300px;
		height: auto;
		z-index: 9999;
		position: absolute;
		right: 0;
		padding: 10px 20px;
		border-radius: 8px;
		background: #000;
		color: #fff;
		opacity: 0.8;
	}

	.tip-box div p {
		line-height: 1.5;
	}

	.tip-box:hover div {
		display: block;
	}
</style>
<style scoped>
	.el-dialog__body {
		padding: 30px 20px 60px;
	}
</style>